import imgA from "../../src/assets/images/alphabets/A.png";
import imgB from "../../src/assets/images/alphabets/B.png";
import imgC from "../../src/assets/images/alphabets/C.png";
import imgD from "../../src/assets/images/alphabets/D.png";
import imgE from "../../src/assets/images/alphabets/E.png";
import imgF from "../../src/assets/images/alphabets/F.png";
import imgG from "../../src/assets/images/alphabets/G.png";
import imgH from "../../src/assets/images/alphabets/H.png";
import imgI from "../../src/assets/images/alphabets/I.png";
import imgK from "../../src/assets/images/alphabets/K.png";
import imgL from "../../src/assets/images/alphabets/L.png";
import imgM from "../../src/assets/images/alphabets/M.png";
import imgN from "../../src/assets/images/alphabets/N.png";
import imgO from "../../src/assets/images/alphabets/O.png";
import imgP from "../../src/assets/images/alphabets/P.png";
import imgQ from "../../src/assets/images/alphabets/Q.png";
import imgR from "../../src/assets/images/alphabets/R.png";
import imgS from "../../src/assets/images/alphabets/S.png";
import imgT from "../../src/assets/images/alphabets/T.png";
import imgU from "../../src/assets/images/alphabets/U.png";
import imgV from "../../src/assets/images/alphabets/V.png";
import imgW from "../../src/assets/images/alphabets/W.png";
import imgX from "../../src/assets/images/alphabets/X.png";
import imgY from "../../src/assets/images/alphabets/Y.png";

export const letterBank = [
  { word: "A", img: imgA },
  { word: "B", img: imgB },
  { word: "C", img: imgC },
  { word: "D", img: imgD },
  { word: "E", img: imgE },
  { word: "F", img: imgF },
  { word: "G", img: imgG },
  { word: "H", img: imgH },
  { word: "I", img: imgI },
  { word: "J" },
  { word: "K", img: imgK },
  { word: "L", img: imgL },
  { word: "M", img: imgM },
  { word: "N", img: imgN },
  { word: "O", img: imgO },
  { word: "P", img: imgP },
  { word: "Q", img: imgQ },
  { word: "R", img: imgR },
  { word: "S", img: imgS },
  { word: "T", img: imgT },
  { word: "U", img: imgU },
  { word: "V", img: imgV },
  { word: "W", img: imgW },
  { word: "X", img: imgX },
  { word: "Y", img: imgY },
  { word: "Z" },
];

export const wordBank = [
  { word: "Table" },
  { word: "Chair" },
  { word: "Computer" },
  { word: "Pen" },
  { word: "Paper" },
  { word: "Lunch" },
  { word: "School" },
  { word: "Teacher" },
  { word: "Apple" },
  { word: "Pencil" },
  { word: "Eraser" },
  { word: "Recess" },
  { word: "Homework" },
  { word: "Exam" },
  { word: "Textbook" },
  { word: "Library" },
  { word: "Calculator" },
  { word: "Scissors" },
  { word: "Principal" },
  { word: "Art" },
  { word: "Chalk" },
  { word: "Geography" },
  { word: "Math" },
  { word: "English" },
  { word: "Science" },
  { word: "Physics" },
  { word: "Chemistry" },
  { word: "Biology" },
  { word: "Algebra" },
  { word: "Bus" },
  { word: "Detention" },
  { word: "Uniform" },
  { word: "Economics" },
  { word: "Experiment" },
  { word: "Grade" },
  { word: "Gym" },
  { word: "Lesson" },
  { word: "Music" },
  { word: "Presentation" },
  { word: "Study" },
  { word: "Whiteboard" },
  { word: "Glue" },
  { word: "Ruler" },
  { word: "Tape" },
  { word: "Paint" },
  { word: "Crayons" },
  { word: "Markers" },
  { word: "Binders" },
  { word: "Dictionary" },
  { word: "Highlighter" },
];
